.hero {
  background-image: url('https://placehold.co/1920x1080');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  /* Set the height to the full viewport height */
  height: calc(100vh - (100px + 1rem));

  /* Ensure the width is also the full viewport width if the content does not fill it up */
  min-width: 100vw;

  /* Other content styles */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  color: var(--dark-grey-text);
  /* padding: 0 100px; */
}

.hero-content {
  width: 568px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 100px;
  padding-right: 80px;
  background-color: var(--purple);
  opacity: 0.8;
  border-bottom-right-radius: 70%;
  border-top-right-radius: 70%;
  color: white;
}

.hero .button {
  margin-top: 32px;
  width: fit-content;
}

.hero .title {
  font-size: 64px;
  line-height: 96px;
  margin: 0;
  span {
    color: var(--gold);
  }
  font-weight: 500;
}

.hero .title-display {
  font-size: 96px;
  line-height: 96px;
}

.hero-content p {
  padding: 16px 0;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-size: 20px;
  line-height: 32px;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.content-wrapper {
  position: absolute;
  width: 500px;
  padding-left: 100px;
  color: white;
  max-width: calc(100vw - 100px);
  animation: fadeIn 1s;
}

.hero-right {
  .content-wrapper {
    padding-left: 0;
    padding-right: 100px;
  }
}

.hero-content-white h1, .hero-content-white p {
  color: #fff;
}

.hero-content .button:hover {
  background-color: var(--gold-hover);
  border-color: var(--gold-hover);
}

.hero-right {
  justify-content: flex-end;
  text-align: right;
}
.hero-right .hero-content {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 70%;
  border-bottom-left-radius: 70%;
  align-items: end;
}

.hero-right .hero-content .button {
  justify-self: flex-end;
}

.hero-center {
  justify-content: center;
  text-align: center;
}

.hero-center .hero-content {
  opacity: 1;
  background-color: unset;
  align-items: center;
}

.footer-hero {
  background-color: var(--purple);
  height: auto;
  padding: 60px 0;
  width: 100vw;
  p {
    color: #fff;
  }
  .hero-content {
    width: 668px;
    .title {
      color: #fff;
      font-size: 54px;
      line-height: 75px;
    }
  }
}

@media (max-width: 768px) {
  .hero {
    padding: 0 16px;
    width: 100vw;
    align-items: start;
    min-width: auto;
    justify-content: center;
  }
  .hero-content, .hero-right .hero-content {
    width: calc(100vw - 46px);
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 36px;
    height: 360px;
    border-bottom-right-radius: 120%;
    border-bottom-left-radius: 120%;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    justify-content: start;

  }
  .content-wrapper {
    max-width: calc(100vw - 32px);
    text-align: center;
    padding-top: 50px;
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
  .title, .title-display {
    font-size: 60px !important;
    line-height: 61px !important;
  }
  .hero-content p {
    font-size: 18px;
    line-height: 28px;
  }
  .button {
    width: calc(100% - 32px);
  }
  .footer-hero {
    p {
      font-size: 16px;
      line-height: 32px;
    }
    .hero-content {
      padding: 60px 0;
      .title {
        font-size: 42px;
        line-height: 50px;
      }
    }
  }
}
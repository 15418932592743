.filters_wrapper {
  display: flex;
  flex-direction: row;
  width: calc(100vw - 72px);
  justify-content: flex-end;
  padding: 16px 36px;
  .back-button {
    margin-right: auto;
  }
  .filter {
    margin-left: 16px;
  }
  .clear {
    padding: 6px 12px;
    margin-left: 18px;
  }
}

.filters {
  span {
    font-weight: bold;
    color: var(--copy-text);
  }
}

@media (max-width: 768px) {
  .filters_wrapper {
    flex-direction: column;
    padding: 16px;
    width: calc(100vw - 48px);
    .filter {
      margin: 0px 0px 24px 0px;
    }
    .clear {
      margin-left: 0;
      width: auto;
    }
  }
  .filters {
    span {
      padding-bottom: 18px;
      display: block;
    }
  }
}
.contactUs {
  padding: 32px 0;
  h1 {
    padding-left: 24px;
  }
  text-align: left;
  p {
    padding: 8px 24px 8px 24px;
    font-family: "Inter", Arial, Helvetica, sans-serif;
  }
  .hero {
    margin: 58px 0;
    h1 {
      padding-left: 0;
    }
  }
  .orgList {
    display: flex;
    padding: 16px 24px;
    flex-wrap: wrap;
    .org {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }
  .orgContent {
    h2 {
      height: 62px;
      padding-bottom: 0px;
    }
    p:first-of-type {
      margin-top: 0px;
    }
  }
  .links a {
    text-decoration: none;
  }
}
@media (max-width: 768px) {
  .contactUs {
    width: 100vw;
    padding: 16px;
    .orgList {
      flex-direction: column;
      display: flex;
      align-items: center;
      .org {
        margin-bottom: 16px;
        margin-right: 0px;
      }
    }
    .hero {
      width: auto;
    }
  }
}
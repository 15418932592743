.eventCarouselContainer {
  display: flex;
  flex-direction: column;
  padding: 32px 32px 64px 32px;
  max-width: calc(100vw - 64px);
  .eventCarouselHeader {
    display: flex;
    justify-content: space-between;
    /* padding: 0 80px; */
    align-items: center;
    width: calc(100vw - 128px);
    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .eventCarousel {
    max-width: 1520px;
    height: 508px;
    display: flex;
    max-width: 100vw;
    overflow-y: hidden;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 80px;

    .event {
      margin: 16px;
    }
  }
}

@media (max-width: 768px) {
  .eventCarouselContainer {
    padding: 32px;
    padding-right: 0;
    min-width: calc(100vw - 32px);
    .eventCarouselHeader {
      flex-direction: column;
      padding: 0 16px;
      align-self: center;
      padding-right: 32px;
    }
  }
  .react-multi-carousel-list {
    padding-left: calc((100vw - 356px - 64px) / 2) !important;
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
}

.react-multi-carousel-list {
  padding: 42px 0px;
}
@media (max-width: 768px) {
  .nav-items {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    flex-direction: column;
    width: 100%;
    /* Full width */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .nav-items.open {
    display: flex;
  }

  .hamburger-menu {
    display: flex;
  }
}

.navbar {
  position: relative;
}

.nav-items {
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
}

.nav-item {
  margin-right: 58px;
  text-decoration: none;
  color: var(--dark-grey-text);
  font-size: 20px;
  &:hover {
    text-decoration: underline;
  }
  &:active {
    font-weight: bold;
  }
}

.nav-item.contact {
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  padding: 18px 32px;
  color: var(--white);
  background-color: var(--purple);
  border: 2px solid var(--purple);
  border-radius: 8px;
  margin-right: 0;
  &:hover {
    background-color: var(--purple-hover);
    border: 2px solid var(--purple-hover);
  }
}

.navbar-mobile {
  position: absolute;
  /* top: -300px; */
  top: -200px;
  right: 0; 
  width: 100%;
  /* transition: right 0.3s; */
  transition: all .5s ease-in-out;
  z-index: 99;
  .nav-item {
    margin-right: 0;
    margin: 12px 0;
  }
  .nav-item:first-of-type {
    padding-top: 16px;
  }
}

.navbar-mobile--open {
  top: 120px;
}
.eventDetails {
  max-width: 1520px;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  padding: 0px 64px 32px 64px;

  .back-button {
    padding: 16px 4px;
  }

  p,
  li {
    color: var(--copy-text);
    font-size: 20px;
    line-height: 32px;
  }

  p {
    padding-bottom: 24px;
  }

  .location {
    width: 38%;
  }

  img {
    border-radius: 16px;
    max-width: 100%;
    height: 600px;
    width: 100vw;
    object-fit: cover;
    object-position: center 35%;
  }

  #map {
    width: 100%;
    padding-right: 0px;
    padding-top: 24px;

    div:first-of-type {
      padding-right: 0px;
    }
  }

  .eventDetailsHeader {
    max-height: 600px;
    /* overflow: hidden; */
    border-radius: 16px;

    div {
      display: flex;
      justify-content: space-between;
      padding-top: 12px;
      align-items: center;
      font-size: 14px;
      line-height: 14px;

      img {
        padding-right: 12px;
        height: 28px;
        border-radius: unset;
        object-fit: unset;
        max-width: 28px;
      }

      div {
        color: var(--copy-text);
      }

      span {
        cursor: pointer;
        font-weight: bold;
        display: flex;
        align-items: center;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .eventDetailsContent {
    padding-top: 52px;
    display: flex;

    div:first-of-type {
      width: 60%;
      padding-right: 36px;
    }
  }

  @media (max-width: 768px) {
    padding: 0px 24px 24px 24px;
    max-width: calc(100vw - 48px);

    .back-button {
      padding: 8px 4px;
    }

    .eventDetailsHeader {
      img {
        height: 316px;
        object-fit: cover;
        max-width: 100%;
      }

      img+div {
        flex-direction: column;
        align-items: start;

        div {
          padding-bottom: 12px;
        }
      }
    }

    .eventDetailsContent {
      padding-top: 0px;
      flex-direction: column;

      div:first-of-type {
        width: 100%;
      }

      p,
      li {
        font-size: 16px;
        line-height: 26px;
      }

      .location {
        width: 100%;
      }

      #map {
        div:first-of-type {
          min-width: calc(100vw - 48px);
        }
      }
    }
  }
}
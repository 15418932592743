.button {
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  padding: 18px 32px;
  color: var(--dark-grey-text);
  background-color: var(--gold);
  border: 2px solid var(--gold);
  border-radius: 8px;
  cursor: pointer;
}
.orgDetails {
  /* max-width: 1520px; */
  width: 100vw;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  padding: 0px 64px 32px 64px;
  .back-button {
    position: absolute;
    top: 130px;
    left: 8px;
    z-index: 100;
    color: white;
  }
  p, li {
    color: var(--copy-text);
    font-size: 20px;
    line-height: 32px;
  }
  p {
    padding-bottom: 24px;
    margin-bottom: 0px;
  }
  ul {
    margin-top: 0px;
  }
  img {
    /* border-radius: 16px; */
    max-width: 100%;
    width: 100%;
  }
  .orgDetailsHeader {
    max-height: 600px;
    /* max-width: 1520px; */
    overflow: hidden;
    /* border-radius: 16px; */
    width: 100vw;
    position: relative;
    .orgDetailsOverlay {
      background-color: var(--purple);
      opacity: 0.7;
      height: 100%;
      z-index: 2;
      position: absolute;
      width: 100%;
      padding-top: 0;
    }
    /* .orgDetailsTitleContentWrapper {
      position: absolute;
      z-index: 3;
      top: 128px;
      overflow: visible;
      left: 15%;
    }
    .orgDetailsTitleContent {
      display: flex;
      flex-direction: column;
      h1 {
        color: white;
        font-size: 64px;
        line-height: 72px;
      }
      img {
        max-height: 600px;
        width: calc(80vw - 128px);
        height: auto;
        overflow: hidden;
        border-radius: 16px;
        padding-right: 0;
      }
    } */
    div {
      display: flex;
      justify-content: space-between;
      padding-top: 12px;
      align-items: center;
      font-size: 14px;
      line-height: 14px;
      img {
        padding-right: 12px;
        height: 28px;
        border-radius: unset;
      }
      div {
        color: var(--copy-text);
      }
      span {
        cursor: pointer;
        font-weight: bold;
        display: flex;
        align-items: center;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .orgDetailsTitleContentWrapper {
    position: absolute;
    z-index: 3;
    top: 18%;
    overflow: visible;
    left: 15%;
  }
  .orgDetailsTitleContent {
    display: flex;
    flex-direction: column;
    h1 {
      color: white;
      font-size: 64px;
      line-height: 72px;
      width: 80%;
      height: 144px
    }
    img {
      max-height: 500px;
      width: calc(80vw - 128px);
      height: auto;
      overflow: hidden;
      border-radius: 16px;
      padding-right: 0;
      max-width: calc(1520px * 0.8);
      object-fit: cover;
    }
  }
  .orgDetailsContent {
    display: flex;
    padding: 32px;
    padding-top: 212px;
    width: 1520px;
    align-self: center;
    max-width: calc(100vw - 64px);
    justify-content: center;
    div:first-of-type {
      width: 60%;
      padding-right: 36px;
    }
    .orgContacts {
      display: flex;
      flex-direction: column;
      min-width: 100%;
      div {
        width: 100%;
      }
    }
    .orgContact {
      display: flex;
      flex-direction: column;
      padding-bottom: 24px;
      span {
        padding-bottom: 6px;
      }
      .contactName {
        font-weight: bold;
      }
      .contactEmail a {
        color: var(--green);
        font-weight: normal;
        cursor: pointer;
        text-decoration: underline;
      }
      .contactPhone {
        font-weight: bold;
        color: var(--purple);
      }
    }
  }
  .orgImages {
    padding: 24px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    /* grid-column-gap: 16px; */
    /* grid-row-gap: 16px; */
    img {
      height: 300px;
      width: 50vw;
      object-fit: cover;
      object-position: center 50%;
    }
  }
  @media (max-width: 768px) {
    padding: 24px;
    padding-top: 0px;
    .orgDetailsHeader{
      img {
        /* height: 316px; */
        object-fit: cover;
        height: 300px;
        width: 100%;
      }
      img + div {
        flex-direction: column;
        align-items: start;
        div {
          padding-bottom: 12px;
        }
      }
    }
    .orgDetailsContent {
      flex-direction: column;
      padding-top: 86px;
      div:first-of-type {
        width: 100%;
      }
      p, li {
        font-size: 16px;
        line-height: 26px;
      }
    }
    .orgDetailsTitleContent{
      h1 {
        font-size: 26px;
        line-height: 42px;
        height: 84px;
        width: 90%;
      }
      img {
        width: calc(80vw - 64px);
        max-height: 212px;
        height: 212px;
      }
    }
  }
}
.footer-links {
  min-height: 88px;
  padding: 24px 32px 0px 32px;
  width: calc(100% - 64px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  a {
    color: #000;
    padding-bottom: 24px;
  }
  img {
    height: 54px;
    margin-right: 18px;
    margin-bottom: 24px;
  }
  /* img:first-of-type {
    margin-bottom: 12px;
  } */
  .logo-list {
    display: flex;
    flex-wrap: wrap;
  }
}
.quote {
  font-style: italic;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  padding-top: 12px;
  padding-bottom: 32px;
  color: grey;
  max-width: calc(100vw - 32px);
}
@media (max-width: 1024px) {
  .footer-links {
    flex-direction: column;
    align-items: start;
    padding-bottom: 32px;
  }
}

.back-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    padding: 8px;
    font-weight: bold;
  }
  i {
    padding-right: 4px;
  }
}
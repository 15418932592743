.event {
  min-width: 356px;
  width: 356px;
  min-height: 476px;
  height: 476px;
  display: flex;
  flex-direction: column;
  color: var(--copy-text);
  border: 1px solid #CDCFD1;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.04);
  }
  .eventHeader {
    height: 50%;
    position: relative;
    color: #FFF;
    .date {
      font-family: 'Inter', Arial, Helvetica, sans-serif;
      background-color: var(--green);
      text-align: center;
      position: absolute;
      width: 98px;
      height: 64px;
      bottom: 0;
      h2 {
        margin: 0;
        font-size: 24px;
        line-height: 24px;
        font-family: 'Inter', Arial, Helvetica, sans-serif;
      }
      span {
        font-size: 14px;
        font-weight: 500;
      }
      div {
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
      }
    }
  }
  img {
    width: 100%;
    height: 238px;
    max-height: 100%;
    object-fit: cover;
    display: block;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
  }
  .eventContent {
    text-align: left;
    height: 50%;
    padding: 20px;
    span:first-of-type {
      font-size: 12px;
      text-transform: uppercase;
      font-family: "Roboto", sans-serif;
      line-height: 19px;
      padding-bottom: 14px;
    }
    p {
      font-size: 14px;
      line-height: 22px;
      height: 70px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    h2 {
      color: var(--subtitle-text);
      font-size: 20px;
      line-height: 24px;
      padding-bottom: 14px;
    }
    span:last-of-type {
      color: var(--black);
    }
  }
}
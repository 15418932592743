:root {
  --purple: #AE4182;
  --purple-hover: #952869;
  --green: #4B9B74;
  --gold: #FFB124;
  --gold-hover: #E6980B;
  --orange: #ff6600;
  --dark-grey-text: #3E3939;
  --dark-grey-text: #333;
  --background-color: #f8f8f8;
  --white: #fff;
  --black: #000;
  --copy-text: #354149;
  --subtitle-text: #03111B;
}

.app {
  text-align: center;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  overflow-x: hidden;
}

/* .playfair-display-<uniquifier> {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
} */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: var(--background-color);
  height: 100px;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  /* width: 50px; */
  /* Adjust as needed */
}

.logo-text {
  margin-left: 0.5rem;
  font-weight: bold;
  color: var(--light-grey-text);
}

.explore-button {
  background-color: var(--orange);
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  /* Or any color you prefer */
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 100;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 70px;
  /* Adjust size as needed */
  margin-right: 10px;
}

.logo-text {
  font-size: 24px;
  /* Adjust size as needed */
  font-weight: bold;
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.menu-line {
  height: 2px;
  width: 25px;
  background-color: var(--light-grey-text);
  margin: 3px 0;
}

h1, h2, h3, h4, h5 {
  font-family: 'Playfair Display', 'Inter', Arial, Helvetica, sans-serif;
}

.content {
  display: flex;
  width: 100vw;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.head-leaf {
  width: 518px;
  height: 517px;
  top: 4086px;
  left: 521px;
  angle: -180 deg;

}
.eventsListContainer {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 62px;
  .event {
    margin: 24px 36px;
  }
}
@media (max-width: 768px) {
  .eventsListContainer {
    justify-content: center;
    .event {
      margin-top: 0;
    }
  }
}